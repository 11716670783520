.card-moderno {
    background: #f9f9f9;
    border: 1px solid #e0e0e0;
  }
  
  
  .info-item {
    margin-bottom: 5px;
    font-size: 1em;
    line-height: 1.6;
  }
  
  @media (max-width: 768px) {
    .card-moderno {
      padding: 10px;
    }
  
    .info-item {
      font-size: 0.8em;
    }
  }

  .dados {
    display: flex;
    justify-content: space-between; /* Alinha os itens lado a lado com espaço entre eles */
    align-items: center; /* Centraliza verticalmente */
    color: #000000;
    box-shadow: 0 2px 4px rgba(34, 34, 34, 0.1);
    
}
  
  .contato, .documentos, .informacoes {
    display: flex;
    justify-content: space-between; /* Alinha os itens lado a lado com espaço entre eles */
    align-items: center; /* Centraliza verticalmente */
    color: #3f3f3f;
    box-shadow: 0 2px 4px rgba(34, 34, 34, 0.1);

}



.contato p, .documentos p, .informacoes p {
    margin: 0; /* Remove a margem padrão dos parágrafos */
    flex: 1; /* Permite que cada parágrafo ocupe espaço igual */
}

.endereco-principal {
  background-color: #f7f7f7;
  box-shadow: 0 2px 4px rgba(34, 34, 34, 0.1);
 
}

.endereco-dados {
  border-radius: 5px;
  margin: 10px 0;
  display: flex;
  justify-content: space-between; /* Alinha os itens lado a lado com espaço entre eles */
    align-items: center;
}

.endereco-dados p {
  margin: 0; 
  flex: 1; 
}

.endereco-info {
  margin-bottom: 10px;
  font-size: 1em;
  line-height: 1.6;
  
}








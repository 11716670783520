.date-container h3 {
  margin-right: 20px !important; /* Espaçamento à direita do título */
  white-space: nowrap !important; /* Impede a quebra de linha */
}

.date-picker {
  padding: 10px !important;
  border: 1px solid #ccc !important;
  border-radius: 4px !important;
  background-color: #fff !important;
  font-size: 14px !important;
  color: #333 !important;
}
  

/* Estilo para o contêiner de data */
.date-container {
  display: flex !important;
  align-items: center !important; /* Alinha os itens verticalmente no centro */
  margin-bottom: 20px !important;
}

/* Estilo para o span */
.date-container span {
  margin: 0 10px !important;
  font-size: 1.2rem !important;
}



/* Garantir que os estilos sejam aplicados ao input do DatePicker */
.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
  width: 100% !important;
}

.react-datepicker__input-container input {
  padding: 10px !important;
  border: 1px solid #ccc !important;
  border-radius: 4px !important;
  background-color: #fff !important;
  font-size: 14px !important;
  color: #333 !important;
}

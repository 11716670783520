.bg-confirmado {
  background-color: #27AC76 !important;
  color: white !important;
  font-weight: bold !important;
  border-radius: 5px !important;
}

.bg-pendente {
  background-color: #ffcc00 !important;
  color: #222 !important;
  font-weight: bold !important;
  border-radius: 5px !important;
}

.bg-desconhecido {
  background-color: #ff3b30 !important;
  color: white !important;
  font-weight: bold !important;
  border-radius: 5px !important;
}

.dropdown-item {
  transition: background-color 0.3s ease-in-out !important;
}

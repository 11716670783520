.table .status-pendente {
    background-color: rgb(255, 213, 0) !important;
    border-radius: 10px !important;
    padding: 0px 0px !important;
    display: inline-block !important;
    font-weight: bold !important;
}

.table .status-vencido {
    color: white !important;
    background-color: rgb(253, 47, 47) !important;
    border-radius: 10px !important;
    padding: 0px 7px !important;
    display: inline-block !important;
    font-weight: bold !important;
}

.table .status-pago {
    color: white !important;
    background-color: rgb(32, 186, 32) !important;
    border-radius: 10px !important;
    padding: 0px 20px !important;
    display: inline-block !important;
    font-weight: bold !important;
}

.table .status-confirmado {
    color: white !important;
    background-color: rgb(32, 186, 32) !important;
    border-radius: 10px !important;
    padding: 0px 0px !important;
    display: inline-block !important;
    font-weight: bold !important;
}

.table .status-desconhecido {
    color: white !important;
    background-color: rgb(253, 47, 47) !important;
    border-radius: 10px !important;
    padding: 0px 10px !important;
    display: inline-block !important;
    font-weight: bold !important;
}

.my-button {
    color: rgb(69, 69, 69) !important;
    font-size: 16px !important;
    text-transform: uppercase !important;
    font-weight: bold !important;
    text-decoration: none !important;
}

.custom-hover:hover {
    color: orange !important;
}

.my-button:hover {
    color: rgb(15, 1, 111) !important;
}

body{
    background-color: var(--bg-dark)
}

/* Login.css */
.btn-login {
    width: 100%;
    margin-top: 10px;
  }


/* Login.css */
.container-login {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f8f9fa; /* Cor de fundo */
    padding: 20px; /* Adicionar espaçamento interno */
  }



  /* Login.css */
.form-group {
    margin-bottom: 20px; /* Aumentar o espaçamento entre os campos */
  }



  .card-login {
    width: 400px;
    margin: auto;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.8); /* Sombras mais acentuadas */
    border-radius: 8px; /* Arredondar as bordas */
    padding: 20px;
    background-color: #f5f5f5; /* Cor de fundo mais escura */
    border: 2px solid var(--bg-dark); /* Cor da borda */
  }

  .form-check-input {
    border: 1px solid var(--bg-dark);
  }

  /* Estilização do checkbox quando marcado */
  .form-check-input:checked {
    border: 2px solid #007bff; /* Borda azul sólida */
  }

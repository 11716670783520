.financeiroMatriz {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #333;
  background-color: #fff;
  padding: 15px;
  border-radius: 0; /* Bordas retas para ocupar toda a largura */
  box-shadow: none; /* Removido para um visual mais limpo em tela cheia */
  margin: 0; /* Removido para ocupar toda a largura */
  border: 1px solid #e0e0e0;
}

.financeiroMatriz h4 {
  color: #0056b3;
  margin-bottom: 10px;
  border-bottom: 1px solid #eee;
  padding-bottom: 3px;
  font-size: 1.2rem; /* Ajuste no tamanho da fonte para tela cheia */
}

.financeiroMatriz label {
  display: block;
  margin-top: 5px;
  font-weight: 600;
  font-size: 1rem;
}

.financeiroMatriz input[type="date"],
.financeiroMatriz select {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 3px;
  font-size: 1rem;
}

.teste button {
  background-color: #007bff;
  color: #ffffff;
  padding: 8px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 15px;
  width: 180px;
}

.teste button:hover {
  background-color: #0056b3;
}

.financeiroMatriz table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 15px;
}

.financeiroMatriz table th,
.financeiroMatriz table td {
  text-align: left;
  padding: 6px;
  border-bottom: 1px solid #eee;
  font-size: 1rem;
}

.financeiroMatriz table th {
  background-color: #f7f7f7;
}

/* Se desejar um layout mais responsivo para a tabela em tela cheia */
@media (max-width: 768px) {
  .financeiroMatriz table, 
  .financeiroMatriz table th, 
  .financeiroMatriz table td {
    font-size: 0.9rem; /* Diminui a fonte para manter a legibilidade */
  }
}


aside.logo {
    background-color: var(--bg-dark);
    display: flex;
    justify-content: center;
    align-items: center;

    position: fixed;
    top: 0;
    left: 0;
    width: 225px;
    height: 10;

}

aside.logo img {
    position: fixed;
    top: 0;
    left: 55px;
    width: 110px;
    height: 90px;

}

aside.logo img:hover{
    filter: drop-shadow(3px 3px 3px rgb(44, 133, 235));

}

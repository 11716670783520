.btn-finalizar{
    align-items: end !important;
    justify-content: center;
}

.bg-green {
    background-color: #60df60 !important; /* Substitua pela cor verde desejada */
  }

  .bg-yellow {
    background-color: #f8f866 !important; /* Substitua pela cor verde desejada */
  }


  .botao-invisivel {
    display: none !important;
  }

  .form-invisivel {
    display: none !important;
  }




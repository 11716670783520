header.header {
    background-color: #fff;
    padding: 0px 15px;
    overflow: hidden;
    white-space: nowrap;
    box-shadow: var(--shadow);
}

header.header h1{
    font-size: 1.8em;
    
}
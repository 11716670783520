.bauble_box {
    display: inline-block;
    position: relative;
    width: 70px; /* Largura do switch reduzida */
    height: 30px; /* Altura do switch reduzida */
    cursor: pointer;
    user-select: none;
  }
  
  .bauble_box .bauble_label {
    background: #c22; /* Mantém a cor para estado inativo */
    border-radius: 30px; /* Ajustado para manter a proporção após a redução do tamanho */
    box-shadow: inset 0 8px 16px rgba(0, 0, 0, .4), 0 -1px 0px rgba(0, 0, 0, .2), inset 0 -1px 0px #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    position: relative;
    transition: all 500ms ease;
    padding: 0 5px; /* Espaçamento interno reduzido */
  }
  
  .bauble_box .bauble_label:before {
    content: 'Inativo';
    color: #fff;
    background-color: #c22;
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .4);
    height: 22px; /* Altura do círculo reduzida */
    width: 35px; /* Largura do círculo reduzida */
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 3px; /* Ajuste na posição inicial para manter o alinhamento */
    transition: all 500ms ease, background-color 500ms ease, color 500ms ease;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px; /* Tamanho do texto reduzido para se adequar ao novo tamanho */
  }
  
  .bauble_box input.bauble_input {
    opacity: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 2;
  }
  
  .bauble_box input.bauble_input:checked + .bauble_label {
    background-color: #2c2; /* Verde quando ativo */
  }
  
  .bauble_box input.bauble_input:checked + .bauble_label:before {
    content: 'Ativo';
    color: #000;
    background-color: #2c2;
    left: calc(100% - 38px); /* Ajustado para mover o círculo para a nova posição correta */
  }

  

    .contato, .documentos, .dados {
    display: flex;
    justify-content: space-between; /* Alinha os itens lado a lado com espaço entre eles */
    align-items: center; /* Centraliza verticalmente */
    font-weight: bold; /* Torna o texto da label em negrito */
    color: #495057;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);


}


.email, .dados-principais{

  font-weight: bold; /* Torna o texto da label em negrito */
  color: #495057;
  

}


.contato p, .documentos p {
    margin: 0; /* Remove a margem padrão dos parágrafos */
    flex: 1; /* Permite que cada parágrafo ocupe espaço igual */
}

.endereco-principal {
  background-color: #f7f7f7;
  box-shadow: 0 2px 4px rgba(34, 34, 34, 0.1);
 
}

.endereco-dados {
  border-radius: 5px;
  margin: 10px 0;
  display: flex;
  justify-content: space-between; /* Alinha os itens lado a lado com espaço entre eles */
    align-items: center;
}

.endereco-dados p {
  margin: 0; 
  flex: 1; 
}

.endereco-info {
  margin-bottom: 10px;
  font-size: 1em;
  line-height: 1.6;
}



.endereco .form-group {
  margin-bottom: 20px; /* Espaçamento entre os grupos de formulário */
}

.endereco .form-control {
  border: 1px solid #ced4da; /* Borda sutil */
  border-radius: 0.25rem; /* Bordas arredondadas */
  padding: 0.375rem 0.75rem; /* Padding interno */
  font-size: 1rem; /* Tamanho da fonte */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra sutil para profundidade */
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; /* Transição suave */
}

.endereco .form-control:focus {
  border-color: #000000; /* Cor da borda ao focar */
  outline: 0; /* Remove o outline padrão */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra sutil para profundidade */
}

.endereco .form-label {
  font-weight: bold; /* Torna o texto da label em negrito */
  margin-bottom: 0.5rem; /* Espaçamento abaixo da label */
  display: inline-block; /* Garante que a label não ocupe toda a largura */
  color: #495057; /* Cor da fonte das labels */
}

.endereco .row {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.endereco .col {
  flex: 1;
  min-width: 160px; /* Mantém uma largura mínima para responsividade */
}

/* Ajuste específico para o campo de número */
.endereco .col-numero {
  flex: 0 1 auto; /* Flex-grow, flex-shrink e flex-basis */
  max-width: 200px; /* Define uma largura máxima para o campo número */
}


.custom-Adiantamentos {
    color: rgb(255, 204, 0);
    padding: 10px;
    text-shadow: 1.2px 1px 2px rgb(50, 50, 50);

  }

  .custom-Boletos {
    color: rgb(239, 0, 0);
    padding: 10px;
    text-shadow: 1.2px 1px 2px rgb(114, 114, 114);

  }

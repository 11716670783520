.link {
  text-decoration: none;
  color: inherit;
  transition: color 0.1s ease;
}

.link:hover {
  color: rgb(39, 0, 215);
}

tr:hover td .link {
  color: rgb(39, 0, 215);
}




:root{
    --bg-dark: #1a2f3a;

    --logo-height: 100px;
    --header-height: 100px;
    --aside-width: 225px;
    --footer-height: 40px;

    --shadow:
        0 2px 23px 0 rgba(0,0,0,0.1),
        0 2px 49px 0 rgba(0,0,0,0.06);

}


 * {
    box-sizing: border-box;
    font-family: sans-serif;

 }

 .app{
    margin: 0px;
    display:grid;
    grid-template-columns: var(--aside-width) 1fr;
    grid-template-rows:
            var(--header-height )
            1fr
            var(--footer-height);

    grid-template-areas:
            "logo header"
            "menu content"
            "menu footer" ;
    min-height: 100vh;
    background-color: #f5f5f5;


 }
 aside {
   position: fixed;
   top: 100px;
   left: 0;
   width: 225px;
   height: 100%;
   z-index: 10000002;
 }

 aside.logo{

   position: fixed;
   top: 0;
   left: 0;
   width: 100px;
   height: 100%;


    grid-area: logo;

 }

 header.header{
    grid-area: header;
 }

 aside.menu-area{

    grid-area: menu;
 }

 main.content{
    grid-area: content;
 }

 footer.footer{
    grid-area: footer;
 }



  .order-button-container {
    position: relative;
    z-index: 1;
  }

  .custom-modal {
    position: absolute;
    z-index: 2;
  }

.bg-closure-dark{
 background-color: var(--bg-dark) !important;
}

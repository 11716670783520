.debt-status {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgb(248, 4, 4); /* Vermelho intenso */
    color: white; /* Branco para o texto */
    padding: 5px; /* Mantenha algum padding para espaçamento interno */
    height: 40px; /* Altura fixa, ajuste conforme necessário */
    box-shadow: 0 -2px 10px rgba(0,0,0,0.1);
    z-index: 19999999000; /* Z-index muito alto para garantir visibilidade */
    text-align: center; /* Centraliza o texto horizontalmente */
    display: flex;
    align-items: center; /* Alinha o texto verticalmente ao centro */
    justify-content: center; /* Centraliza o conteúdo horizontalmente */
}

aside.menu-area {

    background-color: var(--bg-dark);
    box-shadow:
        2px 0 10px rgba(0, 0, 0, 0.12),
        2px 0 15px rgba(0, 0, 0, 0.92)
}



.menu a {
    display: block;
    text-decoration: none;
    color: #fff;
    font-weight: 300;
    padding: 15px;
}



.a-principal:hover {

    background: linear-gradient(84deg, rgba(3, 80, 255, 0.9416141456582633) 0%, rgba(0, 189, 255, 1) 77%);
    font-size: 1.15em;
}

.dropdown {
    position: relative;
    display: block;
    text-decoration: none;
    color: #fff;
    font-weight: 300;
    padding: 15px;
    font-size: 1.1em;
    z-index: 10000001;

}

.config {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.dropdown-content {
    position: absolute;
    top: -180px;
    left: 100%;
    background-color: var(--bg-dark);
    border: 1px solid #ddd;
    padding: 8px 0;
    width: 200px;
    z-index: 10000000;
    box-shadow:
        2px 0 10px rgba(0, 0, 0, 0.12),
        2px 0 15px rgba(0, 0, 0, 0.92);
}

.dropdown-content a {
    display: block;
    padding: 8px 16px;
    text-decoration: none;
    color: #fff;

}

.dropdown-content a:hover {
    background: linear-gradient(84deg, rgba(3, 80, 255, 0.9416141456582633) 0%, rgba(0, 189, 255, 1) 77%);
    font-size: 1.15em;
}

.fa-angle-up,
.fa-angle-down {
    margin-left: 8px;
}

.fa-angle-right,
.fa-angle-left {
    margin-left: 8px;
}

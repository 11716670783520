//
// price.scss
//

/*********************************/
/*         Pricing & Plans       */
/*===============================*/
.pricing {
    @each $name,
    $value in $theme-colors {
        &.pricing-#{$name} {
            &.business-rate {
                &:hover {
                    border-color: $value !important;
                    .title {
                        color: $value;
                    }
                }
            }

            &.starter-plan {
                border-color: $value !important;
            }

            .name,
            .icon {
                color: $value;
            }
        }
    }
}

.pricing {
    position: relative;
    z-index: 1;
    border-bottom: 3px solid $border-color !important;
    transition: all 0.5s ease;
    .price {
        font-weight: 400;
    }
    &.business-rate {
        &:hover {
            transform: scale(1.03);
            z-index: 2;
            box-shadow: $shadow-md !important;
            background-color: $pricing-bg !important;
        }
    }

    &.starter-plan {
        transform: scale(1.05);
        z-index: 2;
        
        @media (max-width: 767px) {
            transform: scale(1);
        }
    }

    &.best-plan {
        &:hover {
            transform: translateY(-10px);
        }
    }
}

.ribbon {
    @each $name,
    $value in $theme-colors {
        &.ribbon-#{$name} {
            span {
                background-color: #{$value} !important;
                &:before {
                    border-left-color: darken($value, 10%);
                    border-top-color: darken($value, 10%);
                }
        
                &:after {
                    border-right-color: darken($value, 10%);
                    border-top-color: darken($value, 10%);
                }
            }
        }
    }
}

.ribbon {
    position: absolute;
    top: -5px;
    width: 75px;
    height: 75px;
    z-index: 2;
    &.ribbon-right {
        right: -5px;
        span {
            right: -23px;
            transform: rotate(45deg);
        }
    }
    &.ribbon-left {
        left: -5px;
        span {
            left: -21px;
            transform: rotate(-45deg);
        }
    }
    span {
        position: absolute;
        top: 18px;
        width: 100px;
        color: $white;
        &:before,
        &:after {
            content: "";
            position: absolute;
            top: 100%;
            z-index: -1;
            border: 3px solid transparent;
        }
        &:before {
            left: 0;
        }

        &:after {
            right: 0;
        }
    }
}

//page-invoice.html
.invoice-top {
    .logo-invoice {
        font-weight: 700;
        font-size: 27px;
        letter-spacing: 1px;
    }
}


//Cryptocurrency

.crypto-chart {
    position: absolute;
    left: 24px;
    right: 24px;
    bottom: 24px;
    opacity: 0.1;
    z-index: 0;
}

.calculator-block {
    background-color: $white;
    .cryptonatorwidget {
        border: none !important;
        padding: 0 !important;
        background: $light !important;
        input,
        select {
            height: 40px !important;
            line-height: 40px !important;
            margin-bottom: 0;
            border: none;
            background-color: $light !important;
            border: none;
            outline: none;
            box-shadow: $shadow;
            padding: 8px 12px;
            border-radius: 6px;
            background-color: transparent;
            color: $muted;
            font-family: $font-family-base !important;
        }
        div:last-child{
            display: none;
        }
    }
    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: $muted;
        text-transform: capitalize;
    }
    ::-moz-placeholder { /* Firefox 19+ */
        color: $muted;
        text-transform: capitalize;
    }
    :-ms-input-placeholder { /* IE 10+ */
        color: $muted;
        text-transform: capitalize;
    }
    :-moz-placeholder { /* Firefox 18- */
        color: $muted;
        text-transform: capitalize;
    }
}

//Apexcharts
.apexcharts-tooltip {
    background: $apex-tooltip-bg !important;
    color: $apex-tooltip-color;
}

//Switcher-pricing
.switcher-pricing {
    .toggle, .toggler {
        display: inline-block;
        vertical-align: middle;
    }
    
    .toggler {
        transition: all 0.5s ease;
        font-weight: bold;
    }
    
    .toggler--is-active {
        color: $primary !important;
    }

    .form-switch {
        .form-check-input {
            width: 48px;
            height: 24px;
            margin-top: 0;
        }
    }
}

.hide{
    display: none;
}


/* Estilos gerais do componente */
.d-grid {
    margin-top: 20px;
  }

  /* Estilos dos botões */
  .btn-primary {
    background-color: #007bff;
    border-color: #007bff;
    font-weight: bold;
    transition: all 0.3s ease;
    margin-bottom: 10px;
  }

  .btn-primary:hover {
    background-color: #0056b3;
    border-color: #0056b3;
  }

  .btn-primary:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
  }


  .d-grid {
    margin-top: 20px;
  }


  h4 {
    border-bottom: 1px solid #ccc; /* Adiciona uma linha divisória na parte inferior do h4 */
    padding-bottom: 5px; /* Espaçamento inferior para separar o h4 do próximo elemento */
  }

  /* Estilos dos botões de texto */
  .text-button {
    background: none;
    border: none;
    color: #007bff;
    cursor: pointer;
    font-size: 16px;
    margin-bottom: 1px;
    text-decoration: none;
    text-align: left; /* Adiciona o alinhamento à esquerda */
    transition: color 0.3s ease;
    display: block; /* Cada botão é exibido em uma linha separada */
    width: 100%; /* Ocupa toda a largura disponível */
    padding: 0; /* Remove o espaçamento interno do botão */
  }

  .text-button:hover {
    color: #0056b3;
  }

  .text-button:focus {
    outline: none;
  }

  .disabled-btn {
    color: gray;
    pointer-events: none; /* Isso evita que o botão seja clicável */
    cursor: not-allowed; /* Define o cursor do mouse para o estilo de "não permitido" */
  }










/* Estilo geral do container da tabela de faturas */
.faturas-container {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    padding: 20px;
    background-color: #f4f4f4;
    max-width: 100%;
    margin: auto;
}

/* Estilos da tabela de faturas */
.faturas-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
}

.faturas-table th, .faturas-table td {
    text-align: left;
    padding: 12px 15px;
    border-bottom: 1px solid #ddd;
}

.faturas-table th {
    background-color: #007bff;
    color: white;
    font-weight: bold;
}

.faturas-table tbody tr:nth-child(even) {
    background-color: #f2f2f2;
}

/* Estilos para badges de status dentro da tabela de faturas */
.faturas-badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 0.75em;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.375rem;
    color: #ffffff;
}


/* Estilo para a mensagem quando não há faturas, dentro do container específico */
.faturas-container p {
    text-align: center;
    color: #606060;
}
